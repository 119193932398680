var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario cadastro-usuario-notification"},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"box-table"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.itensEntities,"width":"300px","max-width":"900px","single-select":_vm.singleSelect,"show-select":"","flat":"","hide-default-footer":"","loading":_vm.loadingPag,"items-per-page":_vm.itemsPerPage},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BrToolBar',{attrs:{"configFilter":{ listaSelect: _vm.listKeys, getItens: _vm.getItensEntities, jsonData: true },"select":_vm.listaTipoBusca},on:{"search":_vm.buscar}},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btn-filtro",attrs:{"tile":"","outlined":""}},on),[_vm._v(" Instituição ")])]}}])})],1)]},proxy:true},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("phone")(item.phone))+" ")]}},{key:"item.document",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("document")(item.document))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.permiteAcao(_vm.$route, 'edit'))?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","tile":"","outlined":"","color":_vm.variables.colorPrimary},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" Editar ")]):_vm._e(),(_vm.permiteAcao(_vm.$route, 'delete'))?_c('v-btn',{attrs:{"small":"","tile":"","outlined":"","color":_vm.variables.colorError},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" Excluir ")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-footer"},[_c('div',{staticClass:"qtd-paginacao"},[_vm._v(" "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageStop)+" de "+_vm._s(_vm.totalItemSelectMensagemIndividual)+" ")]),(_vm.numberOfPages > 1)?_c('v-pagination',{staticClass:"box-table-paginacao",attrs:{"length":_vm.numberOfPages,"circle":"","disabled":_vm.disabled,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('v-btn',{staticClass:"br-btn",attrs:{"color":"primary"},on:{"click":function($event){return _vm.modalOpen()}}},[_vm._v(" Cadastrar notificação")])],1)]},proxy:true}]),model:{value:(_vm.item.entityIds),callback:function ($$v) {_vm.$set(_vm.item, "entityIds", $$v)},expression:"item.entityIds"}})],1)],1),_c('v-btn',{staticClass:"mx-2 fab-cadastrar-usuario",attrs:{"fab":"","color":"primary"},on:{"click":_vm.cadastrar}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }